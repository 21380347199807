<template>
  <div>
    <b-row>
      <b-col>
        <InputText
          disabled
          textFloat="Name"
          placeholder="Name"
          type="text"
          name="text"
          isRequired
          v-model="form.eventName[indexLanguage].name"
          :v="v.form.eventName.$each[indexLanguage].name"
          :isValidate="v.form.eventName.$each[indexLanguage].name.$error"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12" md="6">
        <InputText
          textFloat="Maximum days booking (Day)"
          placeholder="0"
          type="number"
          name="number"
          v-model="form.maximumDay"
      /></b-col>
      <b-col sm="12" md="6">
        <InputText
          v-model="form.minimumDay"
          textFloat="Minimum days required before booking (Day)"
          placeholder="0"
          type="number"
          name="number"
      /></b-col>
    </b-row>
    <b-row
      ><b-col>
        <InputTag
          :options="optionVenue"
          label="Branch"
          :vmodel="form.venueId"
          :isValidate="v.form.venueId.$error"
          :v="v.form.venueId"
          isAlreadyUsed
          @onSearch="onSearchVenue"
          useServerOption /></b-col
    ></b-row>
    <b-row
      ><b-col sm="12" md="6"
        ><div class="my-3">
          <div class="font-weight-bold mb-2">From Date / time</div>
          <div :class="['input-container']">
            <datetime
              :input-style="styleDatetime"
              format="dd/MM/yyyy"
              value-zone="local"
              placeholder="Please Select Date"
              ref="startReserveDate"
              v-model="form.startReserveDate"
            >
            </datetime>
            <div
              class="icon-primary text-right"
              @click="$refs.startReserveDate.isOpen = true"
            >
              <font-awesome-icon
                icon="calendar-alt"
                class="pointer g-text-gold"
                color="#B41BB4"
              />
            </div>
          </div>
        </div>
      </b-col>
      <b-col sm="12" md="6"
        ><div class="my-3">
          <div class="font-weight-bold mb-2">To Date / time</div>
          <div :class="['input-container']">
            <datetime
              :input-style="styleDatetime"
              format="dd/MM/yyyy"
              value-zone="local"
              placeholder="Please Select Date"
              ref="endReserveDate"
              v-model="form.endReserveDate"
              :min-datetime="minDate"
            >
            </datetime>
            <div
              class="icon-primary text-right"
              @click="$refs.endReserveDate.isOpen = true"
            >
              <font-awesome-icon
                icon="calendar-alt"
                class="pointer g-text-gold"
                color="#B41BB4"
              />
            </div>
          </div></div
      ></b-col>
    </b-row>
    <b-row
      ><b-col sm="12" md="6">
        <InputText
          textFloat="Duration (Minute)"
          placeholder="0"
          type="number"
          name="number"
          v-model="form.dutation"
          :v="v.form.dutation"
          :isValidate="v.form.dutation.$error"
      /></b-col>
      <b-col sm="12" md="6">
        <InputText
          textFloat="Buffer time after (Minute)"
          placeholder="0"
          type="number"
          name="number"
          v-model="form.bufferTimeAfter"
      /></b-col>
    </b-row>
  </div>
</template>

<script>
import InputTag from "@/components/inputs/InputTag.vue";
export default {
  name: "settingTab",
  components: { InputTag },
  props: {
    form: {
      type: Object,
      required: true,
    },
    indexLanguage: {
      type: Number,
      required: true,
    },
    language: {
      type: Object,
      required: false,
    },
    v: {
      type: Object,
    },
  },
  data() {
    return {
      optionAllVenue: true,
      timer: null,
      optionVenue: [],
      filterOptionVenue: {
        keyword: null,
        provinceId: 0,
        eventGroupId: +this.$route.params.id,
      },
      // state sidebar
      styleDatetime: {
        width: "100%",
        border: "none",
      },
    };
  },
  mounted() {
    this.getVeunueOption();
  },
  computed: {
    minDate() {
      return this.form.startReserveDate === "" || !this.form.startReserveDate
        ? null
        : new Date(this.form.startReserveDate).toISOString();
    },
  },
  methods: {
    async getVeunueOption() {
      const respone = await this.$services.servicebranch.getBranchList(
        this.filterOptionVenue,
        true
      );
      if (respone.result === 1) {
        this.optionVenue = respone.detail.data;
      }
    },
    onSearchVenue(val) {
      this.filterOptionVenue.keyword = val;
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.getVeunueOption();
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div>
    <SideBarFilter
      ref="sidebarServiceBranchRoundTimeForm"
      title="Create Round Time"
      textSubmit="Save"
      :filter="form"
      :hideStatusFilter="false"
      :hideSearchBar="true"
      @searchAll="handleSave"
      @clearForm="clearForm"
      handleSideBar
      hideFilter
      :isLoading="isLoading"
      @clearLoading="isLoading = false"
    >
      <template v-slot:filter-option
        ><div class="my-3">
          <InputText
            v-model="form.name"
            class="input"
            textFloat="Name"
            placeholder="Name"
            type="text"
            name="text_name"
          />
        </div>
        <div class="my-3">
          <InputDropdownCheckbox
            name="round"
            textFloat="Day"
            ref="formRoundTimeCreateBranchList"
            :value="form.dayOfWeekId"
            :options="optionDayOfWeek"
            :valueSelectedAll="checkDayAll"
            valueField="id"
            textField="name"
            @onDataChangeCheckAll="(val) => (checkDayAll = val)"
            @onDataChange="handleSelect"
            isRequired
            :isValidate="$v.form.dayOfWeekId.$error"
            :v="$v.form.dayOfWeekId"
          />
        </div>
        <div class="my-3">
          <b-row>
            <b-col cols="6">
              <p class="font-15">
                Time From
                <span class="tw-text-red-500">*</span>
              </p>
              <div
                class="input-container mr-2"
                :class="$v.form.timeFrom.$error && 'error'"
              >
                <!--     -->
                <datetime
                  :input-style="styleDatetime"
                  v-model="form.timeFrom"
                  type="time"
                  format="HH:mm"
                  value-zone="local"
                  placeholder="Time From"
                  ref="form"
                  :isValidate="$v.form.timeFrom.$error"
                  :v="$v.form.timeFrom"
                >
                </datetime>
                <div
                  class="icon-container text-right"
                  @click="$refs.form.isOpen = true"
                >
                  <b-icon
                    icon="clock-fill"
                    class="float-right"
                    color="#B41BB4"
                  ></b-icon>
                </div>
              </div>
              <p class="tw-text-red-500 m-0" v-if="$v.form.timeFrom.$error">
                Please select time
              </p>
            </b-col>
            <b-col cols="6">
              <InputText
                v-model="form.numberOfRound"
                class="input"
                textFloat="Number of round"
                placeholder="0"
                type="number"
                name="numberRoundTime"
                isRequired
                :isValidate="$v.form.numberOfRound.$error"
                :v="$v.form.numberOfRound"
              /> </b-col
          ></b-row></div></template
    ></SideBarFilter>
  </div>
</template>

<script>
import InputDropdownCheckbox from "@/components/inputs/InputDropdownCheckbox.vue";
import { required, minValue, minLength } from "vuelidate/lib/validators";
export default {
  name: "roundTimeForm",
  components: { InputDropdownCheckbox },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    dutation: {
      type: Number,
      required: true,
      default: 0,
    },
    bufferTimeAfter: {
      type: Number,
      required: true,
      default: 0,
    },
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  validations() {
    return {
      form: {
        dayOfWeekId: { required },
        timeFrom: { required },
        numberOfRound: {
          required,
          minValue: minValue(1),
        },
      },
    };
  },
  data() {
    return {
      isLoading: false,
      optionDayOfWeek: [],
      checkDayAll: false,
      styleDatetime: {
        width: "100%",
        border: "none",
      },
      form: {
        dayOfWeekId: [],
      },
    };
  },
  watch: {
    visible(val) {
      if (val === true) {
        this.getDayofWeek();
        this.$refs.sidebarServiceBranchRoundTimeForm.show();
      } else {
        this.$refs.sidebarServiceBranchRoundTimeForm.hide();
      }
    },
  },
  methods: {
    async validateForm() {
      const payload = {
        dutation: this.dutation,
        bufferTimeAfter:
          this.bufferTimeAfter === null ? 0 : this.bufferTimeAfter,
        ProductPriceManage: [
          ...this.items,
          {
            ...this.form,
            timeFrom: this.$moment(this.form.timeFrom).format("HH:mm:ss"),
          },
        ],
      };
      const respone =
        await this.$services.eventgroup.validateProductPriceManageEventList(
          payload
        );
      return respone;
    },
    async getDayofWeek() {
      const getData = await this.$services.master.getOptionDayofWeek();

      if (getData.result == 1) {
        this.optionDayOfWeek = getData.detail;
      }
    },
    clearForm() {
      this.$emit("clearForm");
      this.$refs.formRoundTimeCreateBranchList.clearValue();
      this.$v.$reset();
    },
    formatName() {
      let name = this.form.dayOfWeekId
        .map((e) => this.optionDayOfWeek.find((el) => el.id == e).name)
        .join("-");

      return (
        name +
        "-" +
        this.$moment(this.form.timeFrom).format("HH:mm") +
        "-" +
        this.form.numberOfRound
      );
    },
    async handleSave() {
      this.$v.form.$touch();

      if (this.$v.form.$error) {
        return;
      }
      if ("name" in this.form === false) {
        this.form.name = this.formatName();
      }

      const validate = await this.validateForm();

      if (validate.result === 1) {
        this.$refs.formRoundTimeCreateBranchList.clearValue();
        this.$emit("onSaveForm", this.form);
        this.form = {};
        this.$emit("clearForm");
      } else {
        this.$emit("openModalAlertText", validate.detail.message);
      }
    },
    handleSelect(val) {
      this.form.dayOfWeekId = val;
      this.$v.$reset();
    },
  },
};
</script>

<style lang="scss" scoped>
.font-15 {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 5px;
}
</style>

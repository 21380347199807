<template>
  <div>
    <b-row>
      <b-col>
        <b-table
          responsive
          striped
          hover
          :fields="fields"
          :items="items"
          :busy="isBusy"
          show-empty
          empty-text="No matching records found"
        >
          <template v-slot:cell(action)="{ item }">
            <div class="container">
              <font-awesome-icon
                class="pointer"
                icon="trash-alt"
                @click="onDelete(item)"
              />
            </div>
          </template>
        </b-table>
        <p class="tw-text-red-500" v-if="v.form.productPriceManage.$error">
          Please Create RoundTime
        </p>
      </b-col>
    </b-row>
    <hr />
  </div>
</template>

<script>
export default {
  name: "TableScheduleTab",

  props: {
    items: {
      type: Array,
      required: true,
    },
    v: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        {
          key: "name",
          label: "Name",
          class: "w-50px text-nowrap",
        },
        {
          key: "timeFrom",
          label: "Open Date",
          class: "w-50px text-nowrap",
        },
        {
          key: "numberOfRound",
          label: "Number of Round",
          class: "w-50px text-nowrap",
        },
        {
          key: "action",

          class: " text-nowrap",
        },
      ],
      rows: 0,

      isBusy: false,
    };
  },
  methods: {
    onDelete(val) {
      const { index } = val;
      this.items.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped></style>

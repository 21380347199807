<template>
  <div class="create-service-branch">
    <h2 class="mb-3 text-black">Create Service Branch</h2>
    <b-container class="bv-example-row bv-example-row-flex-cols">
      <div class="d-stepper tw-mt-8 tw-mb-2">
        <div class="d-stepper-header d-flex justify-content-around">
          <div
            class="step-number-content text-center"
            :class="{ active: current_step == i }"
            v-for="(stepItem, i) in max_step"
            :key="i"
          >
            <div
              class="step-number align-items-center justify-content-center mx-auto"
              :class="stepNumberClasses(i)"
            >
              <span>{{ i + 1 }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="tw-text-right">
        <b-dropdown
          v-if="language"
          :text="language.name"
          class="tw-h-10 tw-m-4 tw-min-w-10"
        >
          <b-dropdown-item
            v-for="(item, index) in listLang"
            :key="index"
            @click="changeLanguage(item, index)"
          >
            <div :class="language.languageId === item.languageId && `item`">
              {{ item.name }}
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </div> -->
      <section>
        <div class="tw-h-5/6" v-show="current_step === 0">
          <SettingTab
            :form="form"
            :index-language="indexLanguage"
            :language="language"
            :v="$v"
          />
        </div>
        <div class="tw-h-5/6" v-show="current_step === 1">
          <ScheduleTab
            :form="form"
            :items="form.productPriceManage"
            :v="$v"
            @openModalAlertText="openModalAlertText"
          />
        </div>
      </section>
      <div
        class="tw-sticky tw-bottom-0 tw-bg-white tw-z-40 tw-w-full tw-h-16 tw-px-3 tw-mt-10"
      >
        <div class="tw-flex tw-justify-between">
          <b-button
            class="btn-filter cancel"
            variant="secondary"
            @click="onClickBack"
            >Back</b-button
          >
          <b-button
            class="btn-filter save"
            @click="onClickNext"
            :disabled="loading"
            >{{ current_step < max_step - 1 ? "Next" : "Save" }}
            <b-spinner
              class="m-0 ml-1"
              label="Spinning"
              small
              v-if="loading"
            ></b-spinner
          ></b-button>
        </div>
      </div>
    </b-container>
    <ModalConfirmDelete
      :nameItem="''"
      @confirm="confirmModal"
      :text="'หากท่านกดยืนยันข้อมูลที่ท่านกรอกจะหายไปทั้งหมด'"
    />
    <ModalAlertText :textModal="textModal" :arrModal="[]" />
  </div>
</template>

<script>
import Stepper from "@/components/Stepper.vue";
import SettingTab from "./component/settingTab/index.vue";
import ScheduleTab from "./component/scheduleTab/index.vue";
import ModalConfirmDelete from "@/components/modal/ModalConfirmDelete.vue";
import ModalAlertText from "@/components/modal/ModalAlertText.vue";
import { required, requiredIf, minValue } from "vuelidate/lib/validators";
export default {
  name: "CreateServiceBrnchListPage",
  components: {
    Stepper,
    SettingTab,
    ScheduleTab,
    ModalConfirmDelete,
    ModalAlertText,
  },
  data() {
    return {
      form: {
        venueId: [],
        eventName: [
          { languageId: 1, name: "" },
          { languageId: 2, name: "" },
        ],
        eventGroupId: +this.$route.params.id,
        isLockProductEvent: true,
        productPriceManage: [],
        price: 0,
        quantity: 1,
      },
      current_step: 0,
      max_step: 2,

      selectedLang: 2,
      listLang: [],
      loading: false,
      textModal: "",
    };
  },
  validations() {
    return {
      form: {
        venueId: { required },
        eventName: {
          $each: {
            name: {
              required: requiredIf(() => {
                const requiredLanguage = this.form.eventName.find(
                  (x) => x.languageId === 2
                );
                return !requiredLanguage.name && this.selectedLang === 2;
              }),
            },
          },
        },
        dutation: {
          required: requiredIf(() => this.current_step === 0),
          minValue: minValue(1),
        },
        price: {
          required: requiredIf(() => this.current_step === 1),
        },
        quantity: {
          required: requiredIf(() => this.current_step === 1),
        },
        productPriceManage: {
          required: requiredIf(() => this.current_step === 1),
        },
      },
    };
  },
  computed: {
    language() {
      return this.listLang.length > 0
        ? this.listLang.find((x) => x.languageId === this.selectedLang)
        : {};
    },
    indexLanguage() {
      return this.form.eventName.findIndex(
        (x) => x.languageId === this.selectedLang
      );
    },
  },
  mounted() {
    this.getLanguageOption();
    this.getEventGroupDetail();
  },
  methods: {
    async getEventGroupDetail() {
      const respone = await this.$services.eventgroup.eventGroupDetail(
        +this.$route.params.id
      );
      if (respone.result == 1) {
        const { translationList, eventGroupPrice } = respone.detail;
        this.form.eventName.forEach((item) => {
          const eventName = translationList.find(
            (x) => x.languageId == item.languageId
          );
          if (eventName) {
            item.name = eventName.name;
          }
        });
        this.form.price = eventGroupPrice;
      }
    },
    async getLanguageOption() {
      const getData = await this.$services.master.getLanguageOption();

      if (getData.result == 1) {
        this.listLang = getData.detail;
        this.selectedLang = this.listLang[0].languageId;
      }
    },
    async handleSave() {
      this.loading = true;
      const payload = {
        ...this.form,
        endReserveDate:
          this.form.endReserveDate === "" ? null : this.form.endReserveDate,
        startReserveDate:
          this.form.startReserveDate === "" ? null : this.form.startReserveDate,
      };
      const respone = await this.$services.eventgroup.insertEventList(payload);

      if (respone.result === 1) {
        this.$router.replace({
          path: `/service/detail/${this.$route.params.id}`,
          query: { tab: "4" },
        });
      } else {
        this.openModalAlertText(respone.detail.message);
      }
      this.loading = false;
    },
    changeLanguage(v) {
      this.selectedLang = v.languageId;
    },
    stepNumberClasses(i) {
      return {
        "current-step  text-white": this.current_step === i,
      };
    },
    onClickNext: function () {
      this.$v.form.$touch();

      if (this.$v.form.$error) {
        return;
      }

      if (this.current_step < this.max_step - 1) {
        this.current_step++;
      } else {
        this.handleSave();
      }
      this.$v.form.$reset();
    },
    onClickBack: function () {
      switch (this.current_step) {
        case 0:
          this.$router.push({
            path: `/service/detail/${this.$route.params.id}`,
            query: { tab: "4" },
          });
          break;
        case 1:
          this.openModalConfirm();
          break;
        default:
          break;
      }
    },
    backStep() {
      if (this.current_step > 0) this.current_step--;
    },
    confirmModal() {
      this.clearForm();
      this.backStep();
      this.closeModalConfirm();
    },
    clearForm() {
      this.form.productPriceManage = [];

      this.form.quantity = null;
    },
    openModalConfirm() {
      this.$bvModal.show("modal-confirm-delete");
    },

    closeModalConfirm() {
      this.$bvModal.hide("modal-confirm-delete");
    },
    openModalAlertText(text) {
      this.textModal = text;
      this.$bvModal.show("modal-alert-text");
    },
  },
};
</script>

<style lang="scss" scoped>
.current-step {
  background: var(--secondary-color);
}
.old-step {
  background: var(--secondary-color);
}
.create-service-branch {
  background: white;
  padding: 15px;

  min-height: 60vh;

  position: relative;

  .text-black {
    color: black;
  }
  .input-flex {
    width: 100%;
  }
  .footer-save {
    position: absolute;
    bottom: 10px;
    right: 10px;

    .btn-save {
      display: flex;
      justify-content: center;
      align-items: center;

      background: var(--secondary-color);
    }
  }
}
// ! override
.btn-filter {
  width: 100%;
  max-width: 150px;

  &.cancel {
    border: none;
    background: #606060;
    color: white !important;
  }

  &.save {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--secondary-color);
    border: none;
    color: white !important;
  }
  &.save:hover {
    background: var(--secondary-color);

    cursor: pointer;
  }
  &.cancel:hover {
    background: #606060;
    color: white !important;

    cursor: pointer;
  }
}
</style>

<template>
  <div>
    <div class="header-title">
      <h1 class="title">RoundTime <span class="highlight">*</span></h1>
      <b-button @click="handleSideBar">RoundTime</b-button>
    </div>
    <b-row
      ><b-col><Table :items="items" :v="v" /></b-col
    ></b-row>
    <b-row>
      <b-col sm="12" md="6">
        <InputText
          disabled
          v-model="form.price"
          textFloat="Price"
          placeholder="0"
          type="number"
          name="number"
          :v="v.form.price"
          :isValidate="v.form.price.$error"
      /></b-col>
      <b-col sm="12" md="6">
        <InputText
          v-model="form.quantity"
          textFloat="Quantity"
          placeholder="0"
          type="number"
          name="number"
          :v="v.form.quantity"
          :isValidate="v.form.quantity.$error"
      /></b-col>
    </b-row>
    <RoundTimeForm
      :visible="visible"
      @onSaveForm="onSaveForm"
      @clearForm="clearForm"
      :dutation="form.dutation"
      :bufferTimeAfter="form.bufferTimeAfter"
      :items="items"
      @openModalAlertText="openModalAlertText"
    />
  </div>
</template>

<script>
import Table from "./component/table.vue";
import RoundTimeForm from "./component/roundTimeForm.vue";
export default {
  name: "scheduleTab",
  components: { Table, RoundTimeForm },
  props: {
    form: {
      type: Object,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    v: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { visible: false };
  },
  methods: {
    handleSideBar() {
      this.visible = true;
    },
    onSaveForm(val) {
      const addItem = {
        index: this.items.length,
        ...val,
        timeFrom: this.$moment(val.timeFrom).format("HH:mm:ss"),
      };
      this.items.push(addItem);
    },
    clearForm() {
      this.visible = false;
    },
    openModalAlertText(val) {
      this.$emit("openModalAlertText", val);
    },
  },
};
</script>

<style lang="scss" scoped>
.header-title {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
}
.title {
  font-size: 18px;

  .icon {
    cursor: pointer;
    font-size: 14px;
  }
}
.highlight {
  color: red;
}
</style>
